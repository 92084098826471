<template>
  <DefaultButton
    icon
    v-bind="$attrs"
    :class="['zg-default-switch-button pa-0']"
    bgColor="white"
    color="black"
    :disabled="disabled"
    @click.prevent="$emit('clicked')"
  >
    <v-icon
      style="vertical-align: middle; font-size: 40px !important"
      outline
      >{{ icon }}</v-icon
    >
  </DefaultButton>
</template>

<script>
export default {
  props: {
    right: Boolean,
    left: Boolean,
    disabled: Boolean,
  },
  components: {
    DefaultButton: () => import("@/components/buttons/DefaultButton"),
  },
  computed: {
    icon() {
      if (this.right) {
        return "mdi-chevron-right";
      } else {
        return "mdi-chevron-left";
      }
    },
  },
};
</script>

<style lang="css" scoped>
.zg-default-switch-button {
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
}
</style>